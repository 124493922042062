<template>
  <div class="button-field">
    <a
      :href="input.url"
      target="_blank"
    >
      <div class="form-button">
        <label class="form-button-text">
          {{ lang[activeLang][input.title] || input.title }}
        </label>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'FormButton',
  props: {
    formData: {
      type: Object,
      default: new Object()
    },
    activeLang: {
      type: String,
      default: 'English'
    },
    input: {
      type: Object,
      required: true
    },
    inputKey: {
      type: String,
      default: null
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button-field {
  margin-top: 1em;
}

a {
  text-decoration: none;
}

.form-button {
  background-color: #a3dbe3;
  color: #fff;
  padding: 10px 0;
  border-radius: 0px;
  cursor: pointer;
  height: 100%;
  padding: 15px 30px;
  font-weight: 600;
  font-size: 1rem;
}

.form-button-text {
  pointer-events: none;
}
</style>
