<template>
  <div class="container-main">
    <div
      v-if="debugging"
      class="debugging-container"
    >
      <pre v-if="JSONData">
      <code>
        {{ JSONData }}
      </code>
      </pre>
      <h1 style="color: rgb(214, 88, 88)">
        DEBUGGING MODE ENABLED.
      </h1>
      <div
        class="debug-button"
        @click="clearFormData"
      >
        <label class="submit-button-text"> CLEAR LOCAL DATA </label>
      </div>
      <div
        class="debug-button"
        @click="submitForm"
      >
        <label class="submit-button-text"> TEST SUBMIT </label>
      </div>
    </div>
    <FormCompleteHeader
      v-if="submitted === true"
      id="FormCompleteHeader"
      :active-lang="activeLang"
    />
    <FormHomeHeader
      v-else-if="!isLocationValid"
      :active-lang="activeLang"
    />
    <FormFileHeader
      v-else-if="isLocationValid"
      id="FormFileHeader"
      :active-lang="activeLang"
    />
    <label
      v-if="formError === true"
      id="error-notice"
    >
      {{ lang[activeLang].review_error }}</label>
    <Loading v-if="loading" />
    <div v-if="submitted != true && !loading">
      <slot v-for="(formSection, index) in formStructure">
        <form-section
          v-if="
            typeof formSection.shouldShow === 'undefined' ||
              (typeof formSection.shouldShow != 'undefined' &&
                formSection.shouldShow() === true)
          "
          :id="index"
          :key="index"
          :title="index"
          :form-section="formSection"
          :active-lang="activeLang"
          :form-data="formData"
          @formUpdate="formFieldChanged"
        />
      </slot>
      <div
        v-if="isLocationValid"
        class="submit-button"
        @click="submitForm"
      >
        <label class="submit-button-text">
          {{ lang[activeLang].submit }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import FormSection from './FormSection.vue'
import FormHomeHeader from './FormHomeHeader.vue'
import FormFileHeader from './FormFileHeader.vue'
import FormCompleteHeader from './FormCompleteHeader.vue'
import Loading from './Loading.vue'
import formRequest from "../api.js"

function isValidEmail(email) {
  // Regular expression taken from: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
  if (!re.test(email)) {
    return false
  }
  return true
}

function isValidPhoneNumber(number) {
  const re = /^(\+\d+)?\s*\(?\d+\)?[\s.-]?\d*[\s.-]?\d*$/
  if (!re.test(number)) {
    return false
  }
  return true
}

function isValidZipCode(zipCode) {
  const re = /^\d{5}(?:[-\s]\d{4})?$/
  if (!re.test(zipCode)) {
    return false
  }
  return true
}

export default {
  name: 'InquiryForm',
  components: {
    FormSection,
    FormHomeHeader,
    FormCompleteHeader,
    FormFileHeader,
    Loading
  },
  props: {
    activeLang: {
      type: String,
      default: localStorage.activeLang || 'English',
    },
  },
  data() {
    return {
      formData: JSON.parse(localStorage.formData || '{}'),
      erroredFields: [],
      JSONData: null,
      submitted: false,
      formError: false,
      debugging: false,
      loading: true,
      formStructure: {},
    }
  },
  computed: {
    isLocationValid() {
      return this.getFormData('incident_in_state') == 'Yes'
    }
  },
  watch:{
    activeLang() {
      location.reload();
    }
  },
  async beforeMount() {
    this.loading = true
    try {
      await formRequest.requestFormData()
      this.loading = false
    } catch (error) {
      // console.log(error)
    }
  },
  mounted() {
    // Build form inputs here.

    /* 
      INCIDENT LOCATION
    */
    this.addSection('location_select')

    this.addToRow('location_select', 0, 'location_notice', {
      lines: this.lang[this.activeLang].location_select.notice,
      type: 'notice',
    })

    this.addToRow('location_select', 1, 'incident_in_state', {
      title: this.lang[this.activeLang].location_select.county_select.title,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      required: true,
      fullwidth: true,
    })

    this.addToRow('location_select', 2, 'visit_affiliates_notice', {
      lines: this.lang[this.activeLang].location_select.county_select.error.notUtah.notice,
      type: 'notice',
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'No'
      },
    })

    this.addToRow('location_select', 3, 'visit_affiliates', {
      title: this.lang[this.activeLang].location_select.county_select.error.notUtah.title,
      type: 'button',
      url: this.lang[this.activeLang].location_select.county_select.error.notUtah.url,
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'No'
      },
    })

    /* 
      CONTACT INFORMATION SECTION
    */
    this.addSection('contact_info', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes'
      },
    })

    this.addToRow('contact_info', 0, 'notice', {
      lines: this.lang[this.activeLang].contact_info.notice,
      type: 'notice'
    })

    /* 
      AFFECTED CONTACT INFORMATION
      ==================================================================================================================================================================================================================
    */
    this.addToRow('contact_info', 0, 'affected_person', {
      title: this.lang[this.activeLang].contact_info.self_select.title,
      type: 'select',
      options: ['Myself', 'Someone else'],
      optionsObjects: this.lang[this.activeLang].contact_info.self_select.options,
      required: true,
      fullwidth: true,
    })

    this.addToRow('contact_info', 1, 'filer_relationship', {
      title: this.lang[this.activeLang].contact_info.relationship_select.title,
      type: 'select',
      options: ['Relative', 'Spouse', 'Friend'],
      optionsObjects: this.lang[this.activeLang].contact_info.relationship_select.options,
      required: true,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 2, 'affected_notice', {
      lines: this.lang[this.activeLang].contact_info.affected_notice,
      type: 'notice',
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 3, 'affected_salutation', {
      title: 'Salutation',
      type: 'select',
      options: formRequest.apiData.get_salutations.options,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData("affected_person") === 'Someone else'
      },
      required: false,
    })

    this.addToRow('contact_info', 4, 'affected_firstname', {
      title: this.lang[this.activeLang].contact_info.a_first_name,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
      required: true,
    })

    this.addToRow('contact_info', 4, 'affected_lastname', {
      title: this.lang[this.activeLang].contact_info.a_last_name,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
      required: true,
    })

    this.addToRow('contact_info', 5, 'affected_common_name', {
      title: this.lang[this.activeLang].contact_info.a_common_name,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
      fullwidth: false,
      required: false,
    })

    this.addToRow('contact_info', 6, 'affected_pronoun', {
      title: this.lang[this.activeLang].contact_info.pronoun_select.title,
      type: 'select',
      options: formRequest.apiData.get_pronouns.options,
      optionsObjects: this.lang[this.activeLang].contact_info.pronoun_select.options,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else' && formRequest.apiData.get_pronouns.options[0]
      },
    })

    this.addToRow('contact_info', 6, 'affected_pronoun_typed', {
      title: 'Other Pronoun',
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else' && this.getFormData('affected_pronoun') === 'Other'
      },
    })

    this.addToRow('contact_info', 7, 'affected_ethnicity', {
      title: this.lang[this.activeLang].contact_info.ethnic_select.title,
      type: 'select',
      options: formRequest.apiData.get_ethnicities.options,
      optionsObjects: this.lang[this.activeLang].contact_info.ethnic_select.options,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else' && formRequest.apiData.get_ethnicities.options[0]
      },
    })
  
    this.addToRow('contact_info', 7, 'affected_ethnicity_typed', {
      title: 'Other Ethnic/Racial Background',
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else' && this.getFormData('affected_ethnicity') === 'Other'
      },
    })

    this.addToRow('contact_info', 8, 'affected_address', {
      title: this.lang[this.activeLang].contact_info.address,
      type: 'text',
      required: false,
      //required: this.getFormData('affected_person') === 'Myself',
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      }
    })

    this.addToRow('contact_info', 8, 'affected_address2', {
      title: this.lang[this.activeLang].contact_info.address_2,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    
    this.addToRow('contact_info', 8, 'affected_city', {
      title: this.lang[this.activeLang].contact_info.city,
      type: 'text',
      required: this.getFormData('affected_person') === 'Myself',
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })
    

    
    this.addToRow('contact_info', 8, 'affected_state', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'select',
      options: formRequest.apiData.get_states.options,
      required: this.getFormData('affected_person') === 'Myself',
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })
    

    
    this.addToRow('contact_info', 8, 'affected_zip', {
      title: this.lang[this.activeLang].contact_info.zip,
      error: this.lang[this.activeLang].contact_info.error.zip,
      type: 'text',
      required: this.getFormData('affected_person') === 'Myself',
      thirdwidth: true,
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return this.error
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })
    

    this.addToRow('contact_info', 9, 'affected_phone', {
      title: this.lang[this.activeLang].contact_info.phone,
      error: this.lang[this.activeLang].contact_info.error.phone,
      type: 'text',
      required: false,
      //shouldShow: () => {
      //  return this.getFormData('affected_person') === 'Myself'
      //},
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return this.error
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 9, 'affected_email', {
      title: this.lang[this.activeLang].contact_info.email,
      error: this.lang[this.activeLang].contact_info.error.email,
      type: 'text',
      required: false,
      //required: this.getFormData('affected_person') === 'Myself',
      isValid(email) {
        if (!isValidEmail(email)) {
          return this.error
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      }
    })

    /*
    this.addToRow('contact_info', 10, 'affected_prison_name', {
      title: this.lang[this.activeLang].contact_info.prison_select.title,
      type: 'select',
      options: formRequest.apiData.get_prisons.options,
      shouldShow: () => {
        return this.getFormData('affected_person') && formRequest.apiData.get_prisons.options[0]
      },
    })
    */

    /*
    this.addToRow('contact_info', 10, 'affected_prison_number', {
      title: this.lang[this.activeLang].contact_info.p_number,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_person') && formRequest.apiData.get_prisons.options[0]
      },
    })
    */

    /* 
      FILER CONTACT INFORMATION 
      ==================================================================================================================================================================================================================
    */
    this.addToRow('contact_info', 10, 'filer_notice', {
      lines: this.lang[this.activeLang].contact_info.filer_notice,
      type: 'notice',
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 11, 'filer_salutation', {
      title: 'Salutation',
      type: 'select',
      options: formRequest.apiData.get_salutations.options,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData("affected_person")
      },
      required: true,
    })

    this.addToRow('contact_info', 12, 'filer_firstname', {
      title: this.lang[this.activeLang].contact_info.first_name,
      type: 'text',
      required: true,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 12, 'filer_lastname', {
      title: this.lang[this.activeLang].contact_info.last_name,
      type: 'text',
      required: true,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 13, 'filer_common_name', {
      title: this.lang[this.activeLang].contact_info.common_name,
      type: 'text',
      fullwidth: false,
      required: false,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 14, 'filer_pronoun', {
      title: this.lang[this.activeLang].contact_info.pronoun_select.title,
      type: 'select',
      options: formRequest.apiData.get_pronouns.options,
      optionsObjects: this.lang[this.activeLang].contact_info.pronoun_select.options,
      shouldShow: () => {
        return this.getFormData('affected_person') && formRequest.apiData.get_pronouns.options[0]
      },
    })

    this.addToRow('contact_info', 14, 'filer_pronoun_typed', {
      title: 'Other Pronoun',
      type: 'text',
      shouldShow: () => {
        return this.getFormData('filer_pronoun') === 'Other'
      },
    })

    this.addToRow('contact_info', 15, 'filer_ethnicity', {
      title: this.lang[this.activeLang].contact_info.ethnic_select.title,
      type: 'select',
      options: formRequest.apiData.get_ethnicities.options,
      optionsObjects: this.lang[this.activeLang].contact_info.ethnic_select.options,
      shouldShow: () => {
        return this.getFormData('affected_person') && formRequest.apiData.get_ethnicities.options[0]
      },
    })

    this.addToRow('contact_info', 15, 'filer_ethnicity_typed', {
      title: 'Other Ethnic/Racial Background',
      type: 'text',
      shouldShow: () => {
        return this.getFormData('filer_ethnicity') === 'Other'
      },
    })

    this.addToRow('contact_info', 16, 'filer_address', {
      title: this.lang[this.activeLang].contact_info.address,
      type: 'text',
      required: false,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 16, 'filer_address2', {
      title: this.lang[this.activeLang].contact_info.address_2,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    
    this.addToRow('contact_info', 17, 'filer_city', {
      title: this.lang[this.activeLang].contact_info.city,
      type: 'text',
      thirdwidth: true,
      required: true,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })
    

    
    this.addToRow('contact_info', 17, 'filer_state', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'select',
      options: formRequest.apiData.get_states.options,
      thirdwidth: true,
      required: true,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })
    

    
    this.addToRow('contact_info', 17, 'filer_zip', {
      title: this.lang[this.activeLang].contact_info.zip_ik,
      error: this.lang[this.activeLang].contact_info.error.zip,
      type: 'text',
      thirdwidth: true,
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return this.error;
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })
    

    this.addToRow('contact_info', 18, 'filer_phone', {
      title: this.lang[this.activeLang].contact_info.phone,
      error: this.lang[this.activeLang].contact_info.error.phone,
      type: 'text',
      required: false,
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return this.error;
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 18, 'filer_email', {
      title: this.lang[this.activeLang].contact_info.email,
      error: this.lang[this.activeLang].contact_info.error.email,
      type: 'text',
      required: false,
      isValid(email) {
        if (!isValidEmail(email)) {
          return this.error;
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    /*
    this.addToRow('contact_info', 14, 'filer_prison_name', {
      title: this.lang[this.activeLang].contact_info.prison_select.title,
      type: 'select',
      options: formRequest.apiData.get_prisons.options,
      shouldShow: () => {
        return this.getFormData('affected_person') && formRequest.apiData.get_prisons.options[0]
      },
    })
    */

    /*
    this.addToRow('contact_info', 14, 'filer_prison_number', {
      title: this.lang[this.activeLang].contact_info.p_number,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_person') && formRequest.apiData.get_prisons.options[0]
      },
    })
    */

    /* 
      COMPLAINT AGAINST
      ==================================================================================================================================================================================================================
    */
    this.addSection('complaint_against', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes'
      },
    })

    this.addToRow('complaint_against', 0, 'against_firstname', {
      title: this.lang[this.activeLang].contact_info.first_name,
      type: 'text',
    })

    this.addToRow('complaint_against', 0, 'against_lastname', {
      title: this.lang[this.activeLang].contact_info.last_name,
      type: 'text',
    })

    this.addToRow('complaint_against', 1, 'against_agency', {
      title: this.lang[this.activeLang].complaint_against.agency,
      type: 'text',
      fullwidth: true
    })

    this.addToRow('complaint_against', 2, 'against_address', {
      title: this.lang[this.activeLang].contact_info.address,
      type: 'text',
    })

    this.addToRow('complaint_against', 2, 'against_address2', {
      title: this.lang[this.activeLang].contact_info.address_2,
      type: 'text',
    })

    this.addToRow('complaint_against', 3, 'against_city', {
      title: this.lang[this.activeLang].contact_info.city,
      type: 'text',
      thirdwidth: true
    })

    this.addToRow('complaint_against', 3, 'against_state', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'select',
      options: formRequest.apiData.get_states.options,
      thirdwidth: true
    })

    this.addToRow('complaint_against', 3, 'against_zip', {
      title: this.lang[this.activeLang].contact_info.zip,
      error: this.lang[this.activeLang].contact_info.error.zip,
      type: 'text',
      thirdwidth: true,
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return this.error;
        }
      },
    })

    this.addToRow('complaint_against', 4, 'against_phone', {
      title: this.lang[this.activeLang].contact_info.phone,
      error: this.lang[this.activeLang].contact_info.error.phone,
      type: 'text',
      fullwidth: true,
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return this.error
        }
      },
    })

    this.addToRow('complaint_against', 5, 'against_affected_permission', {
      title: this.lang[this.activeLang].complaint_against.agency_select.title,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      fullwidth: true
    })

    /* 
      INCIDENT INFORMATION
      ==================================================================================================================================================================================================================
    */
    this.addSection('incident', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes'
      },
    })

    this.addToRow('incident', 0, 'incident_notices', {
      lines: this.lang[this.activeLang].incident_notices.notice1,
      type: 'notice',
    })

    this.addToRow('incident', 1, 'incident_permission_to_speak_with_select', {
      title: this.lang[this.activeLang].incident.permission_to_speak_with_select.title,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      required: true,
      fullwidth: true
    })

    this.addToRow('incident', 2, 'incident_permission_to_speak_with', {
      title: this.lang[this.activeLang].incident.permission_to_speak_with_select.details,
      type: 'textarea',
      charlimit: 1000,
      required: true,
      shouldShow: () => {
        return this.getFormData('incident_permission_to_speak_with_select') == 'Yes'
      },
    })

    this.addToRow('incident', 3, 'incident_notices', {
      lines: this.lang[this.activeLang].incident_notices.notice2,
      type: 'notice',
    })

    this.addToRow('incident', 4, 'incident_share_intake_information_select', {
      title: this.lang[this.activeLang].incident.share_intake_information_select.title,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      required: true,
      fullwidth: true
    })
    
    this.addToRow('incident', 5, 'incident_notices', {
      lines: this.lang[this.activeLang].incident_notices.notice3,
      type: 'notice',
    })

    this.addToRow('incident', 6, 'incident_share_information_with_attorneys_select', {
      title: this.lang[this.activeLang].incident.share_information_with_attorneys_select.title,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      required: true,
      fullwidth: true
    })

    this.addToRow('incident', 7, 'incident_notices', {
      lines: this.lang[this.activeLang].incident_notices.notice4,
      type: 'notice',
    })

    this.addToRow('incident', 8, 'incident_issues', {
      title: 'My issue deals primarily with:',
      type: 'checkbox',
      options: ['Discrimination from the government or a government agency', 'Discrimination from a company or organization', 'Discrimination from an individual person', 'Mistreatment by the police', 'Violation of my rights during a police encounter', 'Violation of my rights during a court case', 'Violation of my rights during my incarceration', 'Mistreatment during incarceration', 'Conditions issues during incarceration', 'Mistreatment in or related to school', 'Restriction of my right to free speech', 'Sexual Orientation/Gender Identity Discrimination', 'Racial/Ethnic Discrimination', 'Religious Discrimination', 'Disability Discrimination', 'Legal issues concerning immigration', 'Other/Not Applicable'],
      fullwidth: true
    })

    this.addToRow('incident', 9, 'incident_reason', {
      title: this.lang[this.activeLang].incident.what_happened,
      type: 'textarea',
      charlimit: 1000,
      required: true,
    })

    this.addToRow('incident', 10, 'incident_date', {
      title: this.lang[this.activeLang].incident.date_select,
      type: 'datepicker',
      required: true,
    })

    this.addToRow('incident', 10, 'incident_county', {
      title: this.lang[this.activeLang].incident.county_select,
      type: 'select',
      options: formRequest.apiData.get_counties.options,
      shouldShow: () => {
        return formRequest.apiData.get_counties.options[0]
      }
    })

    this.addToRow('incident', 11, 'incident_working_with_attorney_select', {
      title: this.lang[this.activeLang].incident.working_with_attorney.title,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      required: true,
      fullwidth: true
    })

    this.addToRow('incident', 12, 'incident_working_with_attorney', {
      title: this.lang[this.activeLang].incident.working_with_attorney.details,
      type: 'textarea',
      charlimit: 1000,
      required: true,
      shouldShow: () => {
        return this.getFormData('incident_working_with_attorney_select') == 'Yes'
      },
    })

    this.addToRow('incident', 13, 'incident_filed_a_complaint_select', {
      title: this.lang[this.activeLang].incident.filed_a_complaint.title,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      required: true,
      fullwidth: true
    })

    this.addToRow('incident', 14, 'incident_filed_a_complaint', {
      title: this.lang[this.activeLang].incident.filed_a_complaint.details,
      type: 'textarea',
      charlimit: 1000,
      required: true,
      shouldShow: () => {
        return this.getFormData('incident_filed_a_complaint_select') == 'Yes'
      },
    })

    this.addToRow('incident', 15, 'incident_resolve_select', {
      title: this.lang[this.activeLang].incident.resolve_select.title,
      sub_title: this.lang[this.activeLang].incident.resolve_select.sub_title,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      required: true,
      fullwidth: true
    })

    this.addToRow('incident', 16, 'incident_resolve_attempt', {
      title: this.lang[this.activeLang].incident.resolve_select.details,
      type: 'textarea',
      charlimit: 1000,
      required: true,
      shouldShow: () => {
        return this.getFormData('incident_resolve_select') == 'Yes'
      },
    })

    this.addToRow('incident', 17, 'incident_incarcerated_select', {
      title: this.lang[this.activeLang].incident.incarcerated_select.title,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      required: true,
      fullwidth: true
    })

    this.addToRow('incident', 18, 'incident_ois_number', {
      title: this.lang[this.activeLang].incident.incarcerated_select.what,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('incident_incarcerated_select') == 'Yes'
      },
    })

    this.addToRow('incident', 18, 'incident_where_incarcerated', {
      title: this.lang[this.activeLang].incident.incarcerated_select.where,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('incident_incarcerated_select') == 'Yes'
      },
    })

    this.addToRow('incident', 19, 'incident_when_incarcerated', {
      title: this.lang[this.activeLang].incident.incarcerated_select.when,
      type: 'text',
      fullwidth: false,
      shouldShow: () => {
        return this.getFormData('incident_incarcerated_select') == 'Yes'
      },
    })

    this.addToRow('incident', 20, 'incident_criminal_defense_attorney_select', {
      title: this.lang[this.activeLang].incident.criminal_defense_attorney.title,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      required: true,
      fullwidth: true
    })

    this.addToRow('incident', 21, 'incident_criminal_defense_attorney', {
      title: this.lang[this.activeLang].incident.criminal_defense_attorney.details,
      type: 'textarea',
      charlimit: 1000,
      required: true,
      shouldShow: () => {
        return this.getFormData('incident_criminal_defense_attorney_select') == 'Yes'
      },
    })

    this.addToRow('incident', 22, 'incident_have_you_filed_select', {
      title: this.lang[this.activeLang].incident.have_you_filed.title,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      required: true,
      fullwidth: true
    })

    this.addToRow('incident', 23, 'incident_have_you_filed', {
      title: this.lang[this.activeLang].incident.have_you_filed.details,
      type: 'textarea',
      charlimit: 1000,
      required: true,
      shouldShow: () => {
        return this.getFormData('incident_have_you_filed_select') == 'Yes'
      },
    })





    /*
    this.addToRow('incident', 26, 'incident_resolution', {
      title: this.lang[this.activeLang].incident.to_do,
      type: 'textarea',
      charlimit: 1000,
      required: true,
    })
    */

    /* 
      DISCLAIMER AND NOTICE.
      ==================================================================================================================================================================================================================
    */
    this.addSection('disclaimer', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes'
      },
    })

    this.addToRow('disclaimer', 0, 'disclaimer_notice', {
      lines: this.lang[this.activeLang].disclaimer.notice,
      type: 'notice',
    })
  },
  methods: {
    formFieldChanged(fieldID, val) {
      // Storing the values into local storage so data is maintained across page navigations.
      this.formData[fieldID] = val
      localStorage.formData = JSON.stringify(this.formData)
    },
    clearFormData() {
      delete localStorage.formData
    },
    async submitForm() {
      // Make sure the form is valid before properly submitting
      const isValid = this.isFormValid()
      this.JSONData = JSON.stringify(this.formData, null, 2)
      if (!isValid) {
        this.formError = true
        // Loop over all erroredFields and find one that has an error.
        for (let i = 0; i < this.erroredFields.length; i++) {
          let input = this.erroredFields[i]
          if (!input.hasError) {
            // Remove it from the array since it's no longer relevent
            this.erroredFields.splice(i, 1)
            continue
          }

          // Scroll to errored element for user to see
          let elmnt = document.getElementById(input.index)
          elmnt.scrollIntoView({ block: 'center' })
          break
        }
      } else {
        const status = await formRequest.submitForm(this.JSONData)
        if (status === 200) {
          this.submitted = true
          this.formError = false      
        }
        // Do not delete stored data if debugging.
         if (!this.debugging) delete localStorage.formData
      }
    },
    addSection(title, sectionData) {
      this.formStructure[title] = sectionData || {}
    },
    addToRow(section, rowID, index, content) {
      content.section = section
      content.index = index
      // Add a content to field to the section if it does not exist already.
      this.formStructure[section].content =
        this.formStructure[section].content || []
      // Create a new row to the section content if it does not exist already.
      this.formStructure[section].content[rowID] =
        this.formStructure[section].content[rowID] || {}
      // Finally add the content to the section, content, row.
      this.formStructure[section].content[rowID][index] = content
    },
    getFormData(index) {
      // Alternative to indexing form data.
      return this.formData[index]
    },
    getInputError(index, input) {
      let showingSection = this.formStructure[input.section].shouldShow

      // If the section is not visible, it is not required to have a value.
      if (showingSection && !showingSection()) return false

      // If the input is not visible, it is not required to have a value.
      let showingInput = input.shouldShow
      if (showingInput && !showingInput()) {
        return false
      }

      // If the input is visible and is not required and is empty we're not gonna perform any valid checks.
      if (!input.required && !this.formData[index]) return false

      // If the input is required and is not inside the formData, then they skipped this input.
      if (!this.formData[index] || this.formData[index] === '') {
        input.hasError = true
        input.requiredError = true
        return true
      }

      // Finally perform valid checks such as email, zip or phone number.
      if (input.isValid) {
        let errorMsg = input.isValid(this.formData[input.index])
        if (errorMsg) {
          input.hasError = true
          input.errorMsg = errorMsg
          return true
        }
      }
    },
    isFormValid() {
      // TODO: Refactor formStructure with a better data structure.
      let isValid = true
      this.errorFields = []
      // eslint-disable-next-line no-unused-vars
      for (let [title, section] of Object.entries(this.formStructure)) {
        for (let inputs of section.content) {
          for (let [inputIndex, input] of Object.entries(inputs)) {
            // Reset any previous errors when performing the form valid check.
            input.hasError = false
            if (this.getInputError(inputIndex, input)) {
              isValid = false
              this.erroredFields.push(input)
            }
          }
        }
      }
      return isValid
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lang-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#error-notice {
  width: 100%;
  color: rgb(214, 88, 88);
  text-align: left;
  font-size: 16px;
  padding-bottom: 20px;
  float: left;
  text-decoration: underline;
}

.debug-button {
  background-color: rgb(214, 88, 88);
  padding: 10px;
  margin: 20px 25%;
  cursor: pointer;
  border-radius: 5px;
}

.container {
  display: flex;
  justify-content: center;
  align-content: flex-start;
}

.submit-button {
  background-color: #a3dbe3;
  color: #fff;
  padding: 10px 30px;
  border-radius: 0px;
  margin: 30px 25% 120px 25%;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.4rem;
}

.submit-button-text {
  pointer-events: none;
}

label {
  color: #fff;
}
</style>
